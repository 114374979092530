<template>
  <MaskView
    @click="$emit('click')"
    border-style="1px solid #eee"
    outerBorderStyle="1px dashed #02a7f0"
  >
    <div class="vo-column align-items-center position-relative w-100">
      <div ref="container" class="vo-row align-items-center w-100">
        <el-image
          fit="contain"
          style="flex: 1; height: 200px"
          :src="item.flavorPicture"
        />
        <div class="status position-absolute">
          <b-badge
            class="custom-badge"
            v-if="item.status == 1"
            href="#"
            :variant="statusVariant"
            >{{ statusText }}</b-badge
          >
        </div>
        <FlavorPieChart
          v-if="pieChartWidth !== 0"
          :legend="legend"
          :series="series"
          height="200px"
          style="flex: 1"
          :width="pieChartWidth"
        />
      </div>
      <div class="mt-4">{{ item.modelName }}</div>
    </div>
    <template #top>
      <div
        class="vo-row align-items-center vo-gap-4 justify-content-end p-2 cursor"
      >
        <el-image
          :src="require('@/assets/images/brand/ic_eyes.svg')"
          @click="$emit('onViewClick')"
        />
      </div>
    </template>
    <template #bottom> </template>
  </MaskView>
</template>

<script>
import MaskView from "@/components/MaskView.vue";
import FlavorPieChart from "@/components/flavor/FlavorPieChart.vue";

export default {
  name: "FlavorItem",
  components: { FlavorPieChart, MaskView },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      legend: {
        orient: "horizontal",
        top: "bottom",
        bottom: "0", // 图例顶部垂直居中
        icon: "circle",
      },

      series: {
        radius: ["45%", "60%"],
        data: this.item.ingredients,
      },
      pieChartWidth: 0,
      pieChartHeight: 0,
    };
  },
  computed: {
    statusVariant() {
      switch (this.item.status) {
        case 1: //禁用
          return "danger";
      }
    },
    statusText() {
      switch (this.item.status) {
        case 1: //禁用
          return "Disabled";
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      const pieChartWidth = `${this.$refs.container.clientWidth}px`;
      this.pieChartWidth = pieChartWidth;
      this.pieChartHeight = `${this.$refs.container.clientHeight}px`;
    });
  },
};
</script>

<style scoped lang="scss">
.status {
  top: 0px;
  left: 0;
  font-size: 20px;
}

::v-deep .badge {
  border-radius: 0;
}

.operate-btn {
  width: 80px;
}

.custom-badge {
  height: 30px; /* 设置高度 */
  line-height: 30px; /* 行高与高度相同，使文本垂直居中 */
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
  text-align: center; /* 确保文本居中对齐 */
  margin-top: 12px;
}
</style>